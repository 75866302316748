<template>
  <section>
    <search-bar-register
      class="mt-2 mb-2 sm:mb-3"
      :title="$t('sectionUsers.searchUsers')"
    />

    <b-table responsive="sm" :fields="fields" :items="items">
      <template #cell(ações)="">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <MoreIcon class="w-3 more__icon" />
          </template>

          <b-dropdown-item :to="`/users/edit/${1}`">
            <EditIcon class="w-3.5 mr-2 icon__actions" />
            <span class="align-middle ml-50">{{ $t("commons.toEdit") }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="modalIsOpen = true">
            <DeleteIcon class="w-3.5 mr-2 icon__actions" />
            <span class="align-middle ml-50">{{ $t("commons.toDelete") }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <footer class="mt-2 flex items-center">
      <Pagination
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :currentPage="currentPage"
        text="usuários"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen="modalIsOpen"
      :closeModal="closeModal"
      :buttonOptions="true"
      :modalText="$t('commons.modalTextDelete')"
      modalFeaturedText="Usuário?"
    />
  </section>
</template>

<script>
import { BTable } from "bootstrap-vue";

import SearchBarRegister from "@core/components/register/SearchBarRegister.vue";
import PageOperationsEnum from "@core/helpers/constants/pagesOperation";
import Modal from "@core/components/commons/modal/Modal.vue";
import Pagination from "@core/components/commons/pagination/Pagination.vue";

import MoreIcon from "@/assets/img/more.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
  name: "ListUsers",
  components: {
    SearchBarRegister,
    Modal,
    Pagination,
    BTable,
    MoreIcon,
    EditIcon,
    DeleteIcon,
  },
  data() {
    return {
      modalIsOpen: false,
      totalItems: 500,
      itemsPerPage: 10,
      currentPage: 1,
      PageOperationsEnum,
      fields: [
        { key: "usuario", sortable: true },
        { key: "status", sortable: true },
        { key: "tipo", sortable: true },
        { key: "ações" },
      ],
      items: [
        {
          usuario: "PrismaFive",
          status: "Ativa",
          tipo: "Administrador",
        },
      ],
    };
  },
  methods: {
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
