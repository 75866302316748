import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListUsers from "@/components/users/ListUsers.vue";

export default {
  components: {
    HeaderRegister,
    ListUsers,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
